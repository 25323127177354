<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../date";
import Navbar2 from "../components/navbar2.vue";
import blogApi from '../apis/blogApi';
import Navbar from "../components/navbar.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      data: {
        title: "",
        longDesc: ""
      },
      login: false,
      load: false
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2,
    Navbar
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
    let id = this.$route.query.id
    this.load = true
    blogApi.get(id, res => {
      this.load = false
      this.data = res.data
    })
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar2 v-else/>
    <!-- 顶部 -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Info</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Info
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!-- 遮蔽罩 -->
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <!-- 项目列表 -->
    <section class="section">
      <div class="container">
        <div class="card border-0 shadow bg-light p-3 pt-4" style="min-height:500px" v-loading="load">
          <h5 class="text-center font-weight-bold">{{ data != null ? data.title : "" }}</h5>
          <hr/>
          <div v-if="data==null">
            <el-empty description="No Content"></el-empty>
          </div>
          <div v-else v-html="data.longDesc"></div>
        </div>
      </div>
      <!--end container-->
    </section>


    <Footer/>
    <!-- Footer End -->
    <Switcher/>
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
